import { ProgramsMutations } from '@/vuex/modules/programs/mutations'
import { cloneDeep } from 'lodash'

export const ProgramsActions = Object.freeze({
  fetchLanguages: 'fetchLanguages',
  fetchProgramsList: 'fetchProgramsList',
  fetchProgramTypes: 'fetchProgramTypes',
  createNewProgram: 'createNewProgram',
  selectProgram: 'selectProgram',
  syncToMsTeams: 'syncToMsTeams',
  fetchMsTeamsSyncStatus: 'fetchMsTeamsSyncStatus',
  duplicateWave: 'duplicateWave',
  addWave: 'addWave',
  fetchProgramModules: 'fetchProgramModules',
  fetchProgramParticipants: 'fetchProgramParticipants',
  fetchProgramEvents: 'fetchProgramEvents',
  archiveWaves: 'archiveWaves',
  getUpdatedProgram: 'getUpdatedProgram',
  updateProgramName: 'updateProgramName',
  setMsOnboardingType: 'setMsOnboardingType',
  setContentSync: 'setContentSync',
  setWaveContentSync: 'setWaveContentSync',
  addExpandedProgramId: 'addExpandedProgramId',
  removeExpandedProgramId: 'removeExpandedProgramId',
  setProgramsScrollTop: 'setProgramsScrollTop',
  updateWaveNumber: 'updateWaveNumber',
  setPage: 'setPage'
})

class MSTSyncError extends Error {}
class ArchiveWavesError extends Error {}

export const generateActions = (lepayaApi) => {
  return {
    [ProgramsActions.fetchLanguages]: async (ctx) => {
      try {
        const response = await lepayaApi.get('/languages')
        if (response.data && response.data.length) {
          ctx.commit(ProgramsMutations.setLanguages, response.data)
        }
      } catch (e) {
        ctx.commit(ProgramsMutations.setLanguages, [ { code: 'en', name: 'English' } ])
      }
    },
    [ProgramsActions.fetchProgramsList]: async (ctx, { page, perPage }) => {
      ctx.commit(ProgramsMutations.setLoading, true)
      try {
        const programsListResponse = await lepayaApi.v6.get(`/programs?page=${page}&perPage=${perPage}`)
        if (page === 1) {
          ctx.commit(ProgramsMutations.setProgramsList, programsListResponse.data)
        } else {
          ctx.commit(ProgramsMutations.addProgramsToList, programsListResponse.data)
        }
      } catch (e) {
        ctx.commit(ProgramsMutations.setError, 'Could not load the programs. Please try again in a few seconds')
      } finally {
        ctx.commit(ProgramsMutations.setLoading, false)
      }
    },
    [ProgramsActions.fetchMsTeamsSyncStatus]: async (ctx, { companyCode }) => {
      ctx.commit(ProgramsMutations.setLoading, true)
      try {
        const waveSyncStatuses = await lepayaApi.get(`/msTeams/sync-status/${companyCode}`)
        ctx.commit(ProgramsMutations.setMsTeamsSyncStatus, waveSyncStatuses.data)
      } finally {
        ctx.commit(ProgramsMutations.setLoading, false)
      }
    },
    [ProgramsActions.fetchProgramTypes]: async (ctx, { companyCode }) => {
      try {
        const programTypes = await lepayaApi.get('/universal-programs')
        if (programTypes.data) {
          ctx.commit(ProgramsMutations.setProgramTypes, programTypes.data)
        }
      } catch (e) {
      }
    },
    [ProgramsActions.createNewProgram]: async (ctx, { companyCode, programTypeId, newProgramName }) => {
      try {
        await lepayaApi.post(`/companies/${companyCode}/programs-catalogue`, {
          name: newProgramName,
          companyCode,
          programId: programTypeId
        })
        return true
      } catch (e) {
        if (e.response && e.response.status === 400) {
          const fieldErrors = Object.keys(e.response.data || {})
          if (fieldErrors.length) {
            throw new Error(e.response.data[fieldErrors[0]]?.[0])
          }
        }
        throw new Error('Unable to create the program')
      }
    },
    [ProgramsActions.selectProgram]: (ctx, { id, val }) => {
      ctx.commit(ProgramsMutations.setSelectedProgram, { id, val })
    },
    [ProgramsActions.syncToMsTeams]: async (ctx, { companyCode, ongoingProgramIds }) => {
      if (!ongoingProgramIds.length) return
      try {
        const response = await lepayaApi.post(`/msTeams/sync-ongoing-programs/${companyCode}`, { ongoingProgramIds: ongoingProgramIds.map(item => String(item)) })
        const errors = new Set(response.data.filter(r => r.error).map(r => r.error))

        if (errors.size === 1) {
          throw new MSTSyncError(Array.from(errors.values()).pop())
        } else if (errors.size === response.data.length) {
          throw new MSTSyncError('Could not sync to MS Teams')
        } else if (errors.size > 0 && errors.size < response.data.length) {
          throw new MSTSyncError('Some items could not be synced to MS Teams')
        } else {
          return response?.data
        }
      } catch (e) {
        if (e instanceof MSTSyncError) {
          throw e
        } else if (e?.response?.data?.title === 'MsTeamsSyncOperationError') {
          throw new MSTSyncError(e?.response?.data?.detail)
        } else {
          throw new Error('Could not sync to MS Teams')
        }
      }
    },
    [ProgramsActions.archiveWaves]: async (ctx, { companyCode, ongoingProgramIds }) => {
      if (!ongoingProgramIds.length) return
      const payload = { ongoingProgramIds: ongoingProgramIds }
      return await lepayaApi.put(`/companies/${companyCode}/waves/archive`, payload)
        .then(async response => response.data)
        .catch(error => {
          if (error.response.status === 400) {
            return (error.response.data)
          } else {
            throw new ArchiveWavesError(`Something went wrong while archiving waves. Please try again. ${error.response.data}`)
          }
        })
    },
    [ProgramsActions.getUpdatedProgram]: (ctx, { companyCode, programId }) => {
      return lepayaApi.v6.get(`/companies/${companyCode}/programs`)
        .then(r => {
          const updatedProgram = r.data.filter(p => p.id === programId)?.pop()
          if (updatedProgram) {
            ctx.commit(ProgramsMutations.updateProgram, updatedProgram)
            return updatedProgram.waves
          }
        })
    },
    [ProgramsActions.duplicateWave]: (ctx, { companyCode, ongoingProgramId, program }) => {
      return lepayaApi.put(`/companies/${companyCode}/programs/${ongoingProgramId}/duplicate-waves`)
        .then(_ => {
          return lepayaApi.v6.get(`/companies/${companyCode}/programs`)
            .then(r => {
              const updatedProgram = r.data.filter(p => p.id === program.id)?.pop()
              if (updatedProgram) {
                ctx.commit(ProgramsMutations.updateProgram, updatedProgram)
                return updatedProgram.waves
              }
              throw new Error('Wave was not duplicated')
            })
        })
    },
    [ProgramsActions.addWave]: (ctx, { lang, companyCode, program }) => {
      const clonedWaves = cloneDeep(program.waves)
      const nextWaveNumber = clonedWaves.length > 0 ? clonedWaves.pop().waveNumber + 1 : 1
      return lepayaApi.post(`/companies/${companyCode}/programs`, {
        waveNumber: nextWaveNumber,
        programCatalogueItemId: program.id,
        journeys: [],
        newJourneys: [],
        isCalendarEnabled: false,
        endOfProgramEmail: true,
        languageCode: lang
      }).then(() => {
        return lepayaApi.v6.get(`/companies/${companyCode}/programs`)
          .then(r => {
            const updatedProgram = r.data.filter(p => p.id === program.id)?.pop()
            if (updatedProgram) {
              ctx.commit(ProgramsMutations.updateProgram, updatedProgram)
              return updatedProgram.waves
            }
            throw new Error('Wave was not created')
          })
      })
    },
    [ProgramsActions.fetchProgramModules]: (ctx, { companyCode, program }) => {
      return lepayaApi.v6.get(`/programs/${program.id}/modules`)
        .then((r) => {
          ctx.commit(ProgramsMutations.setProgramModules, { programId: program.id, modules: r.data })
        }).catch(e => {
          ctx.commit(ProgramsMutations.setProgramModules, { programId: program.id, modules: null })
        })
    },
    [ProgramsActions.fetchProgramParticipants]: (ctx, { companyCode, program }) => {
      return lepayaApi.v6.get(`/companies/${companyCode}/programs/${program.id}/program-participants`)
        .then((r) => {
          ctx.commit(ProgramsMutations.setProgramParticipants, { programId: program.id, participants: r.data })
        }).catch(e => {
          ctx.commit(ProgramsMutations.setProgramParticipants, { programId: program.id, participants: null })
        })
    },
    [ProgramsActions.fetchProgramEvents]: (ctx, { program }) => {
      return lepayaApi.get(`/domain-events/program/${program.id}`)
        .then((r) => {
          ctx.commit(ProgramsMutations.setProgramEvents, { programId: program.id, events: r.data })
        }).catch(e => {
          ctx.commit(ProgramsMutations.setProgramEvents, { programId: program.id, events: null })
        })
    },
    [ProgramsActions.updateProgramName]: (ctx, { companyCode, program, newName }) => {
      return lepayaApi.v6.put(`/companies/${companyCode}/programs/${program.id}/program-name`, {
        name: newName
      }).then(
        () => ctx.dispatch(ProgramsActions.getUpdatedProgram, { companyCode, programId: program.id })
      )
    },
    [ProgramsActions.setMsOnboardingType]: (ctx, { companyCode, onboardingType, waveIds }) => {
      return lepayaApi.put(`/companies/${companyCode}/set-ms-teams-onboarding-type`, {
        msteamsOnboardingType: onboardingType,
        ongoingProgramIds: waveIds
      })
    },
    [ProgramsActions.setContentSync]: (ctx, { companyCode, program, contentSync }) => {
      return lepayaApi.v6.put(`/companies/${companyCode}/programs/${program.id}/content-sync-status`, {
        contentSync
      }).then(
        () => ctx.dispatch(ProgramsActions.getUpdatedProgram, { companyCode, programId: program.id })
      )
    },
    [ProgramsActions.setWaveContentSync]: (ctx, { companyCode, wave, contentSync, program }) => {
      return lepayaApi.v6.put(`/companies/${companyCode}/waves/${wave.id}/content-sync-status`, {
        contentSync
      })
    },
    [ProgramsActions.addExpandedProgramId]: async (ctx, { id }) => {
      try {
        ctx.commit(ProgramsMutations.addExpandedProgramId, id)
      } catch (e) {
      }
    },
    [ProgramsActions.removeExpandedProgramId]: async (ctx, { id }) => {
      try {
        ctx.commit(ProgramsMutations.removeExpandedProgramId, id)
      } catch (e) {
      }
    },
    [ProgramsActions.setProgramsScrollTop]: async (ctx, { top }) => {
      try {
        ctx.commit(ProgramsMutations.setProgramsScrollTop, top)
      } catch (e) {
      }
    },
    [ProgramsActions.updateWaveNumber]: (ctx, { companyCode, waveId, newWaveNumber, programId }) => {
      return lepayaApi.put(`/companies/${companyCode}/waves/${waveId}/wave-number`, {
        waveNumber: newWaveNumber
      })
    },
    [ProgramsActions.setPage]: async (ctx, { page }) => {
      ctx.commit(ProgramsMutations.setPage, page)
    }
  }
}
