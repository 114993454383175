<template>
  <div>
    <NotificationPanel v-if="!!showInvitesSuccess" style="top: 0;position:fixed;width:95%">Calendar invites are being sent, refresh the page to see status</NotificationPanel>
    <section class='font-weight-bold float-right p-4'>
    </section>
    <h1 class="p-4">{{journey.isArchived ? 'Archived module' : 'Module' }}</h1>

    <NotificationPanel v-if="moduleUpdateSuccessMessage" :message=moduleUpdateSuccessMessage></NotificationPanel>
    <NotificationPanel v-if="moduleUpdateErrorMessage" :classes="{ 'bg-danger': true }" icon="exclamation-circle" :message=moduleUpdateErrorMessage></NotificationPanel>

    <Form @focusout.native="formatJourney(journey)" @change="onChange" class="row m-4"
    @submit="submitJourney">

      <h2 class="col-md-12">Details</h2>
      <FormGroup class="col-md-4" :errors="journeyErrors.image">
        <ImageUpload
          :value="journey.image"
          :disabled="journey.isArchived"
          @input="setJourneyImage">
        </ImageUpload>
      </FormGroup>

      <div class="col-md-8">
        <FormGroup :errors="journeyErrors.title">
              <FormLabel text='Module Name' :is-salesforce=isSalesforceModule url='https://lepaya--uat.sandbox.my.salesforce.com/'/>
            <Input size="xl"
                 autofocus
                 class="h1"
                 :value="journey.title"
                 :disabled="journey.isArchived || isSalesforceModule"
                 @input="setJourneyTitle"/>
        </FormGroup>
        <FormGroup>
          <FormLabel text='CS UID'/>
          <div>
            <label maxlength="50" class='ml-2'>
              <a href='#' @click='getCSLink'>{{ journey.csUid }}</a>
            </label>
          </div>
        </FormGroup>
        <FormGroup>
          <FormLabel text='Product Version ID' />
          <div>
            <label maxlength="50" class='ml-2'>
              {{ journey.productVersionId || '-' }}
            </label>
          </div>
        </FormGroup>
        <FormGroup>
          <label>Status</label>
          <div>
            <Badge class="ml-1 font-size:100% text-uppercase" :type="statusTypes">{{isPublished}}</Badge>
          </div>
        </FormGroup>
        <FormGroup>
          <label>Version: {{journey.version || '-'}}</label>
        </FormGroup>

        <FormGroup :errors="journeyErrors.isOptional">
          <label>Optional</label>
          <Checkbox :value="journey.isOptional" :disabled="journey.isArchived || journey.isPublished"
                    @input="setJourneyIsOptional"/>
        </FormGroup>

        <FormGroup :errors="journeyErrors.calendar">
          <label style="opacity: 0.4;">Calendar Invites</label>
          <Checkbox style="opacity: 0.4;" :value="journey.isCalendarEnabled" :disabled="true"/>
          <Pill class="pill-notification" style="color: #2c3239; padding:3px">
            <Icon style="color: #ffbb4d;" icon="exclamation-circle"/>{{calendarInvitesNotificationText}}
          </Pill>
        </FormGroup>

        <FormGroup>
          <label>Deadline</label>
          <Radio :options="deadlineOptions" :value="selectedDeadlineOption" :disabled="journey.isArchived" @input="setJourneyDeadline"/>
        </FormGroup>

        <FormGroup v-if="!hasRelativeDeadline" :errors="journeyErrors.endTime">
          <Datepicker
            @setDate="setJourneyEndTime"
            :value="journey.endTime"
            :disabled="journey.isArchived"
            :disabledDates="disabledDates"
          ></Datepicker>
        </FormGroup>

        <FormGroup v-if="hasRelativeDeadline" :errors="journeyErrors.relativeEndTime">
          <label>Days before due</label>
          <Input type="number" step="1" :value="journey.relativeEndTime" :disabled="journey.isArchived" @input="setJourneyRelativeEndTime"/>
        </FormGroup>
      </div>

      <div v-if="editing && !journey.isArchived" class="col-md-12" >
      <NotificationPanel v-if="deletingClassroomError" :classes="{ 'bg-danger': true }" icon="exclamation-circle"
        :message="deletingClassroomError"></NotificationPanel>
        <div class="col-md-12 p-0 paper">
          <div class="row align-items-center py-3 pl-3 pr-2">
            <div class="col-sm-6 Content-Scheduling">
              <div class="d-flex align-items-center">
                <Icon style="color: #8a93a2;" icon="align-left"/>
                <span class="marginl-10">Classroom</span>
              </div>
            </div>
          </div>
          <Table
            :disabled="true"
            :allDisabled="true"
            v-model="journeyEvents"
            :records="journeyEvents"
            :columns="classroomColumns"
            :show-checkboxes="false"
          />
        </div>

        <NotificationPanel v-if="successScheduleMessage" :message=successScheduleMessage></NotificationPanel>
        <NotificationPanel v-if="failedScheduleMessage" :classes="{ 'bg-danger': true }" icon="exclamation-circle"
                           :message=failedScheduleMessage></NotificationPanel>
        <NotificationPanel
          v-if="publishBitesResponse"
          v-for="(response, index) in publishBitesResponse.message"
          :key="index"
          :classes="publishBiteResponseClass"
          :message="response">
        </NotificationPanel>
        <NotificationPanel v-if="bucketizeError" :classes="{ 'bg-danger': true }" :message=bucketizeError></NotificationPanel>
        <NotificationPanel v-if="bucketizeSuccessMessage" :message=bucketizeSuccessMessage></NotificationPanel>

        <div class="col-md-12 p-0 paper">
          <div class="row align-items-center py-3 pl-3 pr-2">
            <div class="col-sm-3 Content-Scheduling">
              <div class="d-flex align-items-center">
                <Icon style="color: #8a93a2;" icon="align-left"/>
                <span class="marginl-10">Bites</span>
              </div>
            </div>
            <div class="col-sm-9 text-right">
              <Button @click="onAutomaticScheduleBites"
                      v-show="editing && !journey.isArchived && !journey.relativeEndTime && journeyHasScheduledClassroom && showAutoScheduleButton"
                      type="link"
                      class="p-3 font-weight-bold btn-xl mr-3 shadow-lg">
                <IconText icon="clock">Auto Schedule</IconText>
              </Button>
              <Button @click="bucketizeModuleBites"
                      v-if="selectedBites.length && showBucketizeButton"
                      type="link"
                      class="p-3 font-weight-bold btn-xl mr-3 shadow-lg">
                <IconText icon="chalkboard-teacher">Set as session bites</IconText>
              </Button>
              <Button @click="publishBites"
                      v-if="selectedBites.length"
                      type="link"
                      class="p-3 font-weight-bold btn-xl mr-3 shadow-lg">
                <IconText icon="upload">Publish Bites</IconText>
              </Button>
              <Button @click="onAddBites" v-if="showAddBitesButton"
                      type="link"
                      class="p-3 font-weight-bold btn-xl mr-3 shadow-lg">
                <IconText icon="plus">Add Bites</IconText>
              </Button>
              <Button @click="archiveBitesAction"
                      v-if="selectedBites.length"
                      type="link"
                      class="p-3 font-weight-bold btn-xl mr-3 shadow-lg">
                <IconText icon="trash">Archive Bites</IconText>
              </Button>
              <Button @click="fixupBiteData" type="link"
                      class="p-3 font-weight-bold btn-xl mr-3 shadow-lg">
                <IconText icon="medkit">Restore Bite Data</IconText>
              </Button>
            </div>
          </div>
          <BitesList :classroom-scheduled="journeyHasScheduledClassroom" ref="bitesList"/>
        </div>

        <NotificationPanel v-if="participantsNotificationShowing">{{participantsNotificationText}}</NotificationPanel>
        <div class="col-md-12 p-0 paper">
          <div class="row align-items-center py-3 pl-3 pr-2">
            <div class="col-sm-3 content-table-title text-left">
              <Icon style="color: #8a93a2;" icon="align-left"/>
              <span class="ml-2">Participants</span>
            </div>
            <div class="col-sm-3">
              <Pill v-show="selectedParticipants.length > 0" class="pill-notification" style="color: #2c3239">
                <Icon style="color: #ffbb4d;" icon="exclamation-circle"/>
                Selected participants will be removed from this module
              </Pill>
            </div>
            <div class="col-sm-6 text-right">
              <Button v-if="selectedParticipants.length > 0" type="link" class="p-3 font-weight-bold btn-xl mr-3"
                      @click="handleDeleteParticipants">
                <Icon icon="trash" class="mr-2 ml-4"></Icon>
                Remove
              </Button>
              <Button v-else type="link" class="p-3 font-weight-bold btn-xl shadow-lg"
                      @click="addJourneyParticipantsModal">
                <Icon icon="plus-circle" class="icon-plus" style="color: white; background-color: #56d5ec"></Icon>
                Add Participants
              </Button>
            </div>
            <Table
              class="col-sm-12 mt-2"
              :records="journeyParticipants"
              :columns="participantsColumns"
              :rowType="highlightRow"
              v-model="selectedParticipants">
            </Table>
          </div>
        </div>

        <div class="col-md-12 p-0 paper mt-2">
          <div class="row align-items-center py-3 pl-3 pr-2">
            <div class="col-sm-3 Content-Scheduling">
              <div class="d-flex align-items-center">
                <Icon style="color: #8a93a2;" icon="align-left"/>
                <span class="marginl-10">Events</span>
              </div>
            </div>
          </div>
          <Table
              class="col-sm-12 mt-2"
              :records="moduleSyncEvents"
              :columns="syncEventColumns"
          >
          </Table>
        </div>
      </div>

      <div style="height: 20vh; width: 100%;"></div>
    </Form>

    <ButtonsBar v-if="!journey.isArchived" class="position-fixed w-content">

        <template slot="right">
          <Anchor
            :to="cancelTo"
            type="link"
            size="lg"
            class="font-weight-bold p-4">{{backToWavePage ? 'Back to Wave' : 'Back to List' }}</Anchor>

          <Button
             @click="submitJourney"
             size='lg'
             type="primary"
             :disabled="buttonDisabled"
             class="font-weight-bold p-4">{{editing ? 'Update Module' : 'Create new Module' }}
            <Icon class="ml-4" icon='arrow-right'/>
            </Button>
        </template>

      </ButtonsBar>
    <ClassroomZoomStartUrlModal v-if="showZoomStartLinkModal"/>
    <OverlayingSpinner v-if="loading"/>
    <router-view></router-view>

    <b-modal id="modal-remove-classroom" ref="modal-remove-classroom" hide-footer hide-backdrop
             title="Remove Classroom">
      <Form class="col p-4">
        <FormGroup>
          <b-form-input v-model="deleteClassroomReason" placeholder="Cancel reason"></b-form-input>
        </FormGroup>
      </Form>
      <div class="d-flex flex-row justify-content-end w-100" :style="{ gap: '5px' }">
        <b-button variant="outline-dark" @click="resetRemoveClassroomModal">Cancel</b-button>
        <b-button variant="primary" @click="removeEvents">Continue</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-remove-participant" ref="modal-remove-participant" hide-footer hide-backdrop
             title="Remove Participant">
      <template>
        <label>You are removing these participants from the module, they will receive a calendar cancellation for the classroom(s). Do you want to remove them?</label>
        <div class="d-flex flex-row justify-content-end w-100" :style="{ gap: '5px' }">
          <b-button variant="outline-dark" @click="onCloseRemoveParticipants">Cancel</b-button>
          <b-button variant="primary" @click="deleteParticipants">Continue</b-button>
        </div>
      </template>
    </b-modal>
  </div>

</template>

<script>
import FormLabel from '@/components/FormLabel.vue'
import BitesList from '@/components/bites/BitesList.vue'
import ClassroomActions from '@/components/classroom/ClassroomActions.vue'
import ClassroomLocation from '@/components/classroom/ClassroomLocation.vue'
import ClassroomZoomStartUrlModal from '@/components/classroom/ClassroomZoomStartUrlModal.vue'
import OverlayingSpinner from '@/components/common/OverlayingSpinner.vue'
import TableText from '@/components/common/TableText.vue'
import { isFeatureEnabled } from '@/lib/splitIo/handler'
import { featureFlags } from '@/lib/splitIo/models'
import moment from 'moment'
import { complement, equals, isEmpty, isNil, values } from 'ramda'
import { mapActions, mapGetters } from 'vuex'
import Anchor from '../components/Anchor.vue'
import Badge from '../components/Badge.vue'
import Button from '../components/Button.vue'
import ButtonsBar from '../components/ButtonsBar.vue'
import Checkbox from '../components/Checkbox.vue'
import Datepicker from '../components/Datepicker.vue'
import Form from '../components/Form.vue'
import FormGroup from '../components/FormGroup.vue'
import Icon from '../components/Icon.vue'
import IconText from '../components/IconText.vue'
import ImageUpload from '../components/ImageUpload.vue'
import Input from '../components/Input.vue'
import Modal from '../components/Modal.vue'
import NotificationPanel from '../components/NotificationPanel.vue'
import Pill from '../components/Pill.vue'
import Radio from '../components/Radio.vue'
import Table from '../components/Table.vue'
import TextArea from '../components/TextArea.vue'
import classroomTypes from '../components/classroom/models/classroomTypes'
import lepaya from '../http/lepaya'
import store from './JourneyForm.store.js'

const classroomColumns = {
  type: {
    name: 'Type',
    value: ({ location }) => {
      const classroomType = classroomTypes.find(classroomType => classroomType.code === location.classroomType)
      if (!classroomType) return 'Classroom type not set'
      return classroomType.name
    }
  },
  location: {
    name: 'Location',
    component: () => ClassroomLocation
  },
  hostAccount: {
    name: 'Host account',
    component: () => TableText,
    props: ({ meetingDetails, location }) => ({
      text: meetingDetails && location.classroomType === 'zoom' ? meetingDetails.hostEmail
        : meetingDetails && location.classroomType === 'ms_teams' ? meetingDetails.hostEmail : 'Host account not set',
      color: meetingDetails && (location.classroomType === 'zoom' || location.classroomType === 'ms_teams') ? 'black' : 'gray'
    })
  },
  status: {
    name: 'Status',
    component: () => Badge,
    props: ({ meetingLinkStatus }) => {
      let statusType
      if (meetingLinkStatus) {
        switch (meetingLinkStatus) {
          case 'pending':
            statusType = 'secondary'
            break
          case 'failed':
            statusType = 'danger'
            break
          case 'ready':
            statusType = 'success'
            break
          default:
            statusType = 'secondary'
            break
        }
      }
      return {
        large: true,
        type: statusType,
        text: meetingLinkStatus ? meetingLinkStatus.toUpperCase() : 'READY'
      }
    }
  },
  trainer: {
    name: 'Trainer',
    component: () => TableText,
    props: ({ supportTeam }) => {
      const trainer = supportTeam.find(e => e.journeyRole === 'trainer')
      return ({
        text: trainer ? `${trainer.firstName} ${trainer.lastName}` : 'Trainer not set',
        color: trainer ? 'black' : 'gray'
      })
    }
  },
  hours: {
    name: 'Hours',
    value: ({ startTime, endTime }) => `${startTime} - ${endTime}`
  },
  date: {
    name: 'Date',
    value: ({ startDate }) => startDate ? moment(startDate).format('dddd DD-MM-YYYY') : 'Date not set'
  },
  actions: {
    name: 'Actions',
    component: () => ClassroomActions
  }
}

const relativeDeadline = {
  name: 'Relative',
  isFixed: false
}

const fixedDeadline = {
  name: 'Fixed',
  isFixed: true
}

const deadlineOptions = [ fixedDeadline, relativeDeadline ]

const primaryKey = 'id'
const participantsColumns = {
  firstName: {
    name: 'First Name',
    value: ({ firstName }) => firstName
  },
  lastName: {
    name: 'Last Name',
    value: ({ lastName }) => lastName
  },
  email: {
    name: 'Email',
    value: ({ email }) => email
  },
  journeyRole: {
    name: 'Module Role',
    value: ({ journeyRole }) => journeyRole
  },
  id: {
    name: 'Employee Id',
    value: ({ id }) => id
  },
  role: {
    name: 'Role',
    value: ({ role }) => role
  }
}

const syncEventColumns = {
  id: {
    name: 'id',
    value: ({ id }) => id
  },
  name: {
    name: 'Name',
    value: ({ name }) => name
  },
  source: {
    name: 'Source',
    component: () => Badge,
    props: ({ source }) => ({
      text: source?.toUpperCase(),
      type: 'info'
    })
  },
  created_at: {
    name: 'Time',
    value: (row) => row.created_at ? moment(row.created_at).format('DD.MM.YYYY hh:mm:ss') : 'Not Set'
  },
  status: {
    name: 'Status',
    component: () => Pill,
    props: (row) => ({
      large: true,
      color: row.status === 'done' ? 'green' : row.status === 'error' ? 'red' : '',
      text: row.status?.toUpperCase()
    })
  }
}

export default store({
  components: {
    Anchor,
    ButtonsBar,
    Datepicker,
    ImageUpload,
    Checkbox,
    Button,
    Radio,
    TextArea,
    Modal,
    FormGroup,
    Table,
    Input,
    Form,
    Icon,
    IconText,
    Badge,
    Pill,
    NotificationPanel,
    BitesList,
    OverlayingSpinner,
    ClassroomZoomStartUrlModal,
    FormLabel
  },
  mounted () {
    this.onEspace = (e) => {
      if (e.code !== 27) return
      this.selectedParticipants = []
    }
    window.addEventListener('keydown', this.onEspace)
    this.getModuleSyncEvents(this.journey.id)
  },
  destroyed () {
    window.removeEventListener('keydown', this.onEspace)
  },
  data: () => ({
    selectedParticipants: [],
    deadlineOptions,
    participantsColumns: values(participantsColumns),
    syncEventColumns: values(syncEventColumns),
    classroomColumns: values(classroomColumns),
    disabledDates: {
      to: moment().subtract(1, 'days').endOf('day').toDate()
    },
    kickoff: true,
    deletingClassroomError: '',
    syncing: false,
    deleteClassroomReason: '',
    showManualBiteCreation: true
  }),
  async created () {
    this.showManualBiteCreation = await isFeatureEnabled(featureFlags.BITE_MANUAL_CREATION)
  },
  computed: {
    ...mapGetters('bites', {
      showAutoScheduleButton: 'showAutoScheduleButton',
      successScheduleMessage: 'successScheduleMessage',
      failedScheduleMessage: 'failedScheduleMessage',
      selectedBites: 'selectedBites',
      loading: 'loading',
      publishBitesResponse: 'publishBitesResponse',
      bucketizeError: 'bucketizeError',
      showBucketizeButton: 'showBucketizeButton',
      bucketizeSuccessMessage: 'bucketizeSuccessMessage'
    }),
    ...mapGetters('classroom', {
      showZoomStartLinkModal: 'showZoomStartLinkModal'
    }),
    ...mapGetters('modules', {
      moduleUpdateSuccessMessage: 'moduleUpdateSuccessMessage',
      moduleUpdateErrorMessage: 'moduleUpdateErrorMessage',
      moduleSyncEvents: 'moduleSyncEvents'
    }),
    editing () {
      return Boolean(this.journey.id)
    },
    statusTypes () {
      return this.journey.isArchived ? 'grey'
        : this.journey.isPublished ? 'success'
          : 'secondary'
    },
    selectedDeadlineOption () {
      return isNil(this.journey.relativeEndTime)
        ? fixedDeadline
        : relativeDeadline
    },
    isPublished () {
      return this.journey.isArchived ? 'archived'
        : this.journey.isPublished ? 'published'
          : 'draft'
    },
    buttonDisabled () {
      return this.editing && equals(this.journey, this.originalJourney)
    },
    hasRelativeDeadline () {
      return this.selectedDeadlineOption === relativeDeadline
    },
    calendarInvitesNotificationText () {
      return this.journey.isCalendarEnabled ? 'Calendar invites will be sent automatically. Disable this function on the wave page' : 'Calendar invites will not be sent automatically. Enable this function on wave page.'
    },
    showDifferentCatalogueCodeText () {
      return this.editing && !this.journey.isParent && this.journey.parentId &&
      ((!this.journey.parentCatalogueCode && this.journey.catalogueCode !== '') ||
       (this.journey.parentCatalogueCode && this.journey.catalogueCode !== this.journey.parentCatalogueCode))
    },
    showInvitesSuccess () {
      return this.journey.isCalendarEnabled && this.journey.isPublished && this.invitesSuccess
    },
    participantsNotificationShowing () {
      return this.$route.query.add || this.$route.query.delete
    },
    participantsNotificationText () {
      return this.$route.query.delete ? `${this.$route.query.participantsToDelete.length} Participants removed from the module`
        : this.$route.query.add ? `${this.$route.query.newParticipants.length} Participants added to the module` : null
    },
    journeyHasScheduledClassroom () {
      return this.journeyEvents.length >= 1
    },
    addClassroomButtonClass () {
      return this.journeyHasScheduledClassroom ? 'rounded-button-disabled capitalized' : 'rounded-button capitalized'
    },
    publishBiteResponseClass () {
      if (!this.publishBitesResponse) return null
      if (this.publishBitesResponse.status === 'success') return { 'bg-success': true }
      if (this.publishBitesResponse.status === 'partialSuccess') return { 'bg-warning': true }
      if (this.publishBitesResponse.status === 'fail') return { 'bg-danger': true }
    },
    cancelTo () {
      const { programId, programCatalogueItemId } = this.$route.query
      if (!isNil(programId) && !isNil(programCatalogueItemId)) {
        return {
          name: 'editProgram',
          params: { id: programId, programCatalogueItemId }
        }
      }
      return { name: 'listJourneys' }
    },
    backToWavePage () {
      const { programId, programCatalogueItemId } = this.$route.query
      return !isNil(programId) && !isNil(programCatalogueItemId)
    },
    showDuplicationOptionButton () {
      return !this.editing || this.journey.isArchived
    },
    showAddBitesButton () {
      if (!this.editing || this.journey.isArchived) {
        return false
      } else {
        return this.showManualBiteCreation
      }
    },
    isSalesforceModule () {
      return !!this.journey.sfid
    }
  },
  methods: {
    ...mapActions('bites', {
      getBites: 'getBites',
      autoScheduleBites: 'autoScheduleBites',
      publishSelectedBites: 'publishSelectedBites',
      archiveBites: 'archiveBites',
      bucketizeModule: 'bucketizeModule',
      setJourneyIsPublished: 'setJourneyIsPublished'
    }),
    ...mapActions('modules', {
      getModuleSyncEvents: 'getModuleSyncEvents'
    }),
    isNotEmpty: complement(isEmpty),
    onChange () {
      this.buttonDisabled()
    },
    removeEvents () {
      const { companyCode, id } = this.$route.params
      this.deletingClassroomError = ''
      this.$refs['modal-remove-classroom'].hide()
      return Promise.all(this.journeyEvents.map(event => lepaya.delete(`/companies/${companyCode}/journeys/${id}/events/${event.id}`, { data: { deleteClassroomReason: this.deleteClassroomReason } })))
        .then(() => {
          this.deleteClassroomReason = ''
          this.getJourneyEvents(({ id, companyCode }))
          this.getBites({ id, companyCode })
        })
        .catch((error) => {
          this.resetRemoveClassroomModal()
          const [ errorMessage ] = error.response.data.message
          this.deletingClassroomError = (errorMessage || 'Something went wrong')
        })
    },
    addClassroom () {
      this.$router.push({
        name: 'addClassroom',
        params: {
          isCalendarEnabled: this.journey.isCalendarEnabled
        }
      })
    },
    editClassroom (classroom) {
      this.$router.push({
        name: 'editClassroom',
        params: {
          classroomId: classroom.id,
          isCalendarEnabled: this.journey.isCalendarEnabled
        }
      })
    },
    addJourneyParticipantsModal () {
      this.$router.push({
        name: 'addJourneyParticipants',
        params: {
        }
      })
    },
    highlightRow (row) {
      if (row[primaryKey] === this.$route.query[primaryKey] ||
          (Array.isArray(this.$route.query.newParticipants) && this.$route.query.newParticipants.find(id => row[primaryKey] === id))
      ) { return 'success' }
    },
    submitJourney () {
      this.persistJourney({
        companyCode: this.$route.params.companyCode,
        journey: this.journey
      })
        .then(data => {
          const { id } = data
          const payload = {}
          payload.params = { id, companyCode: this.$route.params.companyCode }
          payload.query = {}
          this.getBites({ id, companyCode: this.$route.params.companyCode })
          return this.getJourney(payload)
        })
    },
    onAddBites () {
      this.$store.commit('bites/showNewBiteTypeSelection', true)
    },
    onAutomaticScheduleBites () {
      const { companyCode, id } = this.$route.params
      this.autoScheduleBites({ companyCode, id })
    },

    deleteParticipants () {
      this.$refs['modal-remove-participant'].hide()
      if (!this.selectedParticipants.length) return
      const params = this.$route.params
      const { companyCode, id } = params
      const participantsToDelete = this.selectedParticipants.map(item => item.id)
      const payload = { employeesIds: participantsToDelete }

      return lepaya.delete(`/companies/${companyCode}/journeys/${id}/participants`, { data: payload })
        .then(() => {
          this.getJourneyParticipants(({ id, companyCode }))
          this.$router.push({
            query: { delete: true, participantsToDelete }
          })
          this.selectedParticipants = []
        })
        .catch(({ response: { data: errors } }) => {
          this.errors = errors
        })
    },

    publishBites () {
      const { id, companyCode } = this.$route.params
      this.publishSelectedBites({ id, companyCode })
      this.$refs.bitesList.unselectAll()
    },

    archiveBitesAction () {
      const { id, companyCode } = this.$route.params
      this.archiveBites({ id, companyCode })
      this.$refs.bitesList.unselectAll()
    },

    async fixupBiteData () {
      const { id } = this.$route.params
      const res = await window.confirm('Are you sure you want to restore the bite data?')
      if (res) {
        await lepaya.post(`/contentStack/restore-bite-data/${id}`)
        window.location.reload()
      }
    },

    bucketizeModuleBites () {
      const { id, companyCode } = this.$route.params
      this.bucketizeModule({ id, companyCode })
    },
    resetRemoveClassroomModal () {
      this.$refs['modal-remove-classroom'].hide()
      this.deleteClassroomReason = ''
    },
    onCloseRemoveParticipants () {
      this.$refs['modal-remove-participant'].hide()
    },
    handleDeleteParticipants () {
      if (this.journey.isCalendarEnabled) {
        return this.$refs['modal-remove-participant'].show()
      }
      this.deleteParticipants()
    },
    getCSLink (event) {
      event.preventDefault()
      lepaya.get(`/contentStack/get-module-cs-url/${this.journey.csUid}/${this.journey.locale}`).then(({ data }) => {
        window.open(data.url, '_blank')
      })
    }
  }
})
</script>

<style scoped>
  .Content-Scheduling {
    font-size: 24px;
    font-weight: 300;
    color: #2c3239;
  }

  .rounded-button {
    outline: none;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: #56d5ec;
  }
  .rounded-button-disabled {
    outline: none;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: #8992a0;
  }

  .capitalized {
    text-transform: capitalize;
  }

  .marginl-10 {
    margin-left: 10px;
  }

  .button {
    outline: none;
    font-size: 16px;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(21, 39, 70, 0.05);
    border: solid 1px #ebedf0;
    color: #8c8ca0;
    padding: 10px 15px;
  }

  .deselect {
    font-size: 14px;
    font-style: italic;
    color: #a6a6be;
  }

  .icon-plus {
    margin-right: 4px;
    border-radius: 50%
}
</style>
