<template>
  <div class="modules-table">
    <div class="controls-bar"></div>
    <template v-if="lastFetched">
    <div v-if="tableData.length === 0" class="empty-table">No Modules to show</div>
      <ve-table
          v-else
          :columns="columns"
          :table-data="tableData"
          row-key-field-name="id"
          :sort-option="sortOption"
          :cellSelectionOption="{ enable: false }"
      />
    </template>
    <template v-else>
      <div class="spinner"><b-spinner variant="primary"/></div>
    </template>
  </div>
</template>

<script>
import ModuleTitleCell from '@/components/program/tables/ModulesTable/ModuleTitleCell.vue'
import WaveStatusCell from '@/components/program/tables/WavesTable/WaveStatusCell.vue'
import { ProgramsActions } from '@/vuex/modules/programs/actions'
import { ProgramsGetters } from '@/vuex/modules/programs/getters'
import { PROGRAMS_NAMESPACE } from '@/vuex/modules/programs/programs.state'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import sortChangeMixin from './sortChangeMixin'

export default {
  name: 'ModulesTable',
  mixins: [ sortChangeMixin ],
  data () {
    return {
      lastFetched: null,
      tableData: [],
      sortOption: {
        sortChange: (params) => this.sortChange(params)
      },
      columns: [
        {
          field: 'title',
          key: 'a',
          title: 'Module',
          align: 'left',
          sortBy: '',
          renderBodyCell: ({ row, column, rowIndex }, h) => <ModuleTitleCell module={row} />
        },
        {
          field: 'startDate',
          key: 'b',
          title: 'Start Date',
          align: 'center',
          sortBy: '',
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (row.startDate) {
              return moment(row.startDate).format('DD.MM.YYYY')
            }
            return 'Not set'
          }
        },
        {
          field: 'classroomDate',
          key: 'c',
          title: 'Classroom Date',
          align: 'center',
          sortBy: '',
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (row.startDate) {
              return moment(row.classroomDate).format('DD.MM.YYYY')
            }
            return 'Not set'
          }
        },
        {
          field: 'waveName',
          key: 'd',
          title: 'Wave',
          align: 'left',
          sortBy: ''
        },
        {
          field: 'status',
          key: 'e',
          title: 'Status',
          align: 'left',
          sortBy: '',
          renderBodyCell: ({ row, column, rowIndex }, h) => <WaveStatusCell wave={row} />
        }
      ]
    }
  },
  created () {
    const program = this.$props?.program

    if (this.lastFetched === null) {
      this.fetchProgramModules({ program }).then(r => {
        this.tableData = [ ...this.getProgramModules(program?.id) ]
      }).catch(e => {
        this.tableData = null
      }).finally(() => {
        this.lastFetched = new Date()
      })
    } else {
      this.tableData = [ ...this.getProgramModules(program?.id) ]
    }
  },
  props: {
    modules: Array,
    program: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions(PROGRAMS_NAMESPACE, {
      fetchProgramModules: ProgramsActions.fetchProgramModules
    })
  },
  computed: {
    ...mapGetters(PROGRAMS_NAMESPACE, {
      getProgramModules: ProgramsGetters.getProgramModules
    })
  }
}
</script>

<style lang="scss">

.modules-table > div > div >div >table > thead > tr > th {
    font-weight: 800 !important;
}
.modules-table > div > div >div >table > thead > tr > th > span.ve-table-sort{
    translate: 0 -25%;
}
</style>

<style scoped>

.modules-table {
  margin: 0 0 60px 0;
}

.controls-bar {
  display: flex;
  justify-content: flex-end;
  margin-right: 1em;
  padding: 5px;
  gap: 1rem;
}

.empty-table {
  display: flex;
  justify-content: center;
}

.dropdown {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
}

.dropdown-placer {
  position: relative;
  height: 1px;
}

.dropdown-menu {
  background: white;
  position: absolute;
  min-width: 100%;
  z-index: 9999;
  top: 0;
  right:0;
  margin-top: -1px;
}

.spinner {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 2rem;
}
</style>
